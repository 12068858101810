import { Button, FormControl, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { InspectionNode } from "inspector-common/lib/model";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

interface RoomSelectProps {
    rootNode: InspectionNode
    selectedPath: number[]
    setSelectedPath: (path: number[]) => void
}

function RoomSelect(props: RoomSelectProps) {
    return <FormControl fullWidth>
        {
            props.rootNode.children.map((node, index) => (
                <RoomSelectItem
                    key={index.toString()}
                    node={node}
                    setSelectedPath={props.setSelectedPath}
                    path={[index]}
                    selectedPath={props.selectedPath}
                />
            ))
        }
    </FormControl>
}

const styles = {
    button: {

    }
}

interface RoomSelectItemProps {
    node: InspectionNode
    path: number[]
    selectedPath: number[]
    setSelectedPath: (path: number[]) => void
}

function RoomSelectItem(props: RoomSelectItemProps) {
    const [expanded, setExpanded] = useState(false);

    return <Stack direction="column" spacing={0} pl={1}>
        <Stack direction={"row"} alignItems={"center"}>
            <IconButton onClick={() => setExpanded(!expanded)} disabled={props.node.children.length === 0} sx={{p: 0}}>
                { expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <Button 
                variant={JSON.stringify(props.path) === JSON.stringify(props.selectedPath) ? "contained" : "text" }
                onClick={() => props.setSelectedPath(props.path)}
                sx={styles.button}
            >{props.node.name}</Button>
        </Stack>
        {
            expanded ? props.node.children.map((child, index) => {
                return <RoomSelectItem 
                    key={index.toString()}
                    node={child}
                    path={props.path.concat(index)}
                    selectedPath={props.selectedPath}
                    setSelectedPath={props.setSelectedPath}
                />
            }) : null
        }
        </Stack>
    }
export default RoomSelect;